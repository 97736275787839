import React, { useEffect, useRef, useState } from "react";
import { FaQrcode } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdOutlineCancelScheduleSend } from "react-icons/md";
import QRCode from "react-qr-code";
import { useOutletContext } from "react-router-dom";
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import Layout from "../../components/common/Layout";
import Loading from "../../components/common/Loading";
import { CancelLoyaltyTransferPointsService, ReadLoyaltyTransferPointsService } from "../../services/loyalty";
import "./pointtransferhistory.css";

const ITEMS_PER_PAGE = 5;

const PointsTransferHistory = () => {
    const [loading, setLoading] = useState(false);
    const [userLoginDetails, setUserLoginDetails] = useState({});
    const [histories, setHistories] = useState([]);
    const [totalHistories, setTotalHistories] = useState(0);
    const [totalHistoriesCount, setHistoriesCount] = useState(0);
    const [historiesSearchValue, setHistoriesSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [openQRModal, setOpenQRModal] = useState(false);
    const closeQRModal = () => setOpenQRModal(false);
    const contentRef = useRef(null);
    const [currentUser, tempUserLoginDetails] = useOutletContext();

    const fetchHistories = async (page) => {
        setLoading(true);
        const response = await ReadLoyaltyTransferPointsService(currentUser, '', 'desc', ITEMS_PER_PAGE, page, historiesSearchValue);
        if (response.histories) {
            setHistories(response.histories);
        }
        setTotalHistories(Math.ceil(response.total_histories / ITEMS_PER_PAGE));
        setHistoriesCount(response.total_histories);
        setLoading(false);
    };

    const handlePerRowsChange = async (newPerPage) => {
        fetchHistories(newPerPage);
        setCurrentPage(newPerPage);
    };

    const setHistoriesSearchValueCallback = async (e) => {
        setHistoriesSearchValue(e.target.value);
    }

    const searchHistories = async (e) => {
        e.preventDefault();
        fetchHistories(currentPage);
    }

    const clearSearchedHistories = async (e) => {
        e.preventDefault();
        setHistoriesSearchValue("");
        fetchHistories(currentPage);
    }

    const handleQRCancelClick = async (e, qr_code_chars) => {
        e.preventDefault();
        setLoading(true);
        const response = await CancelLoyaltyTransferPointsService(currentUser, qr_code_chars);
        if (response.success) {
            const historiesCopy = [...histories];
            const historyObj = histories.find(obj => obj.qr_code_chars === qr_code_chars);
            if( historyObj ){
                historyObj.status = 'Cancelled';
                setHistories(historiesCopy);
                setHistoriesCount(response.total_histories - 1);
            }
        } else{
            toast.error('Something went wrong while cancelling it');
        }
        setLoading(false);
    }

    const handleGenerateQRClick = async () => {
        setLoading(true);
        setOpenQRModal(o => !o);
        setLoading(false);
    }

    const handleDownloadClick = () => {
        const svgData = new XMLSerializer().serializeToString(contentRef.current.querySelector('.qr-code'));
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));

        img.onload = function() {
            const padding = 20;
            canvas.width = img.width + padding * 2;
            canvas.height = img.height + padding * 2;
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, padding, padding);
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = 'qr-code.png';
            link.click();
        };
    }

    useEffect(() => {
        (async () => {
            const fetchHistories = async () => {
                setLoading(true);
                const response = await ReadLoyaltyTransferPointsService(currentUser, '', 'desc', ITEMS_PER_PAGE, 0, '');
                if (response.histories) {
                    setHistories(response.histories);
                }
                setTotalHistories(Math.ceil(response.total_histories / ITEMS_PER_PAGE));
                setHistoriesCount(response.total_histories);
                setLoading(false);
            };
            fetchHistories();
            const fetchUserLoginDetails = () => {
                if (tempUserLoginDetails){
                    setUserLoginDetails(tempUserLoginDetails);
                }
            };
            fetchUserLoginDetails();
        })();
    }, [currentUser, tempUserLoginDetails]);

    const tableHead = ["Transferred Date", "Transaction Date", "Receiver", "Points", "Status", "Failed Reason", "Actions"];

    return (
        <Layout title="Points Transfer History" userLoginDetails={userLoginDetails}>
            {loading ? (
                <Loading />
            ) : (
                <div className="business-table-container">
                    <div className="searchbox">
                        <input
                            type="text"
                            className="search-input"
                            placeholder="Search with Mobile ..."
                            value={historiesSearchValue}
                            onChange={setHistoriesSearchValueCallback}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    searchHistories(e)
                                }
                            }}
                        />
                        <a
                            href="/#"
                            onClick={(e) =>
                                searchHistories(e)
                            }>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none">
                                <path
                                    d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                                    stroke="#646975"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M20.9984 20.9984L16.6484 16.6484"
                                    stroke="#646975"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </a>
                        {historiesSearchValue ? (
                            <a
                                href="/#"
                                className="close-icon"
                                onClick={(e) =>
                                    clearSearchedHistories(e)
                                }>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none">
                                    <path
                                        d="M26 2L2 26"
                                        stroke="#3A4750"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M2 2L26 26"
                                        stroke="#3A4750"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </a>) : ("")
                        }
                    </div>
                    <table className="business-table">
                        <thead className="table-header">
                            <tr className="header-row">
                                {
                                    tableHead.map((names, id) => {
                                        return <th key={id} className="header-cell">{names}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {histories.map((item) => (
                                <tr key={item.id} className="table-row">
                                    <td className="table-cell">{item.create_date}</td>
                                    <td className="table-cell">{item.transaction_date}</td>
                                    <td className="table-cell">{item.receiver_mobile_number}</td>
                                    <td className="table-cell">{item.points_transfer}</td>
                                    <td className="table-cell">{item.status}</td>
                                    <td className="table-cell">{item.failed_reason}</td>
                                    <td className="table-cell">
                                        {item.status === 'Pending' ? (
                                            <>
                                                <span className="qr-transfer-cancel">
                                                    <MdOutlineCancelScheduleSend title="Cancel Transaction"
                                                        onClick={(e) => handleQRCancelClick(e, item.qr_code_chars)}/>
                                                </span>
                                                <span className="qr-generate">
                                                    <FaQrcode title="Generate QR" onClick={handleGenerateQRClick}/>
                                                </span>
                                                <Popup open={openQRModal} onClose={closeQRModal}>
                                                    <div className="modal qrmodal">
                                                        <button className="close" onClick={closeQRModal}> &times; </button>
                                                        <div className="content" ref={contentRef}>
                                                            {item.qr_code_chars && (
                                                                <>
                                                                    <QRCode className="qr-code" value={item.qr_code_chars}/>
                                                                    <button className="submit-button dx-btn-primary" onClick={handleDownloadClick}>
                                                                        Download QR
                                                                    </button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Popup>
                                            </>
                                        ) : ''}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {totalHistoriesCount > ITEMS_PER_PAGE ? (
                        <div className="pagination-controls">
                            <button
                                className="pagination-button"
                                onClick={() =>
                                    handlePerRowsChange(currentPage - 1)
                                }
                                disabled={currentPage === 0}
                            >
                                <IoIosArrowBack />
                            </button>
                            <span className="pagination-info">
                                Page {currentPage + 1} of {totalHistories}
                            </span>
                            <button
                                className="pagination-button"
                                onClick={() =>
                                    handlePerRowsChange(currentPage + 1)
                                }
                                disabled={currentPage + 1 === totalHistories}
                            >
                                <IoIosArrowForward />
                            </button>
                        </div>
                    ) : ""}
                </div>
            )}
        </Layout>

    );
};

export default PointsTransferHistory;